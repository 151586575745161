@font-face {
    font-family: 'ITCAvantGardeStd-Bk';
    src:url(./ITCAvantGardeStd-Bk.otf);
}

@font-face {
    font-family: 'ITCAvantGardeStd-Demi';
    src:url(./ITCAvantGardeStd-Demi.otf);
}

@font-face {
    font-family: 'ITCAvantGardeStd-Md';
    src:url(./ITCAvantGardeStd-Md.otf);
}